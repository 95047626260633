import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'
import mechtechPages from './routes/mechtech-pages'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'landing-mechtech' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...mechtechPages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

let hasRedirected = false;

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (to.name === 'landing-mechtech') {
    return next()
  }
  if (to.name === 'landing-subscription-class') {
    return next()
  }
  if (to.name === 'landing-engineering-bootcamp') {
    return next()
  }

  if (to.name === 'about-mechtech') {
    return next()
  }

  if (!hasRedirected && !isLoggedIn) {
    hasRedirected = true;

    next({ name: 'auth-login' })
  } else {
    if (to.meta.redirectIfLoggedIn && isLoggedIn) {
      const userData = getUserData()
      next({ name: 'dashboard-mechtech' })
    }
  
    return next()
  }

  // if (!canNavigate(to)) {
  //   if (!isLoggedIn) return next({ name: 'auth-login' })
  //   return next({ name: 'misc-not-authorized' })
  // }

  // Redirect if logged in

})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
